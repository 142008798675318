import React, { useEffect, useState } from "react";
import { Form, Input, Button, Space, Spin } from "antd";
import axios from "axios";
// import { auth } from "../../utils";
import Utils, { token_cookie_name } from "../../utils";
import "./form.css";

interface IPossibleAnswer {
  val: string;
  text: string;
}

interface FormAttributes {
  value: string;
  options: string[];
  field_type: string;
  data_type: string;
  possible_answers?: IPossibleAnswer[];
}

interface FormData {
  [key: string]: FormAttributes;
}

const WarmUpForm = () => {
  const [formData, setFormData] = useState<FormData>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [saved, setSaved] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_CHAT_SERVER}/api/settings`, {
          headers: {
            Authorization: "Bearer " + Utils.getCookie(token_cookie_name),
            "Content-Type": "application/json",
          },
        });
        setLoading(false);
        if (response?.data?.form) setFormData(response.data.form);
        else {
          alert("Could not fetch static responses");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleAnswerInputChange = (key: string, index: number, value: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: {
        ...prevFormData[key],
        possible_answers: (prevFormData[key]?.possible_answers as Array<IPossibleAnswer>).map((item, i) =>
          i === index ? { ...item, text: value } : item
        ),
      },
    }));
  };

  const handleArrayInputChange = (key: string, index: number, value: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: {
        ...prevFormData[key],
        options: (prevFormData[key]?.options as Array<string>).map((item, i) => (i === index ? value : item)),
      },
    }));
  };

  const handleAddItem = (key: string) => {
    console.info(formData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: {
        ...prevFormData[key],
        options: [...((prevFormData[key]?.options as Array<string>) || []), ""],
      },
    }));
  };

  const handleRemoveItem = (key: string, index: number) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: {
        ...prevFormData[key],
        options: (prevFormData[key]?.options as Array<string>).filter((item, i) => i !== index),
      },
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.info("Saving", formData);
    try {
      const response = await axios.post(`${process.env.REACT_APP_CHAT_SERVER}/api/form/save`, formData, {
        headers: {
          Authorization: "Bearer " + Utils.getCookie(token_cookie_name),
          "Content-Type": "application/json",
        },
      });
      setSaved(true);
      setTimeout(() => {
        setSaved(false);
      }, 5000);
      console.info("Save response", response);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const renderInputFields = () => {
    return Object.keys(formData).map((key) => {
      const value = formData[key]?.options ?? [];
      const answers = formData[key]?.possible_answers;
      const label = formData[key]?.value
        .replace(/_/g, " ")
        .toLowerCase()
        .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
          return match.toUpperCase();
        });

      return (
        <div key={key} className="w-full">
          <Form.Item
            label={
              <span className="font-bold" style={{ fontWeight: "bold" }}>
                {label}: <i className="text-xs">({key})</i>
              </span>
            }
            className="w-full"
          >
            <Space direction="vertical" className="flex flex-col w-full">
              {(value as Array<string>).map((item, index) => (
                <div key={index} className="flex flex-row items-center w-full justify-between">
                  <Input
                    value={item}
                    onChange={(e) => handleArrayInputChange(key, index, e.target.value)}
                    className="mr-10"
                    style={{ marginRight: "10px" }}
                  />
                  {(value as Array<string>).length > 1 && (
                    <Button onClick={() => handleRemoveItem(key, index)}>Remove</Button>
                  )}
                </div>
              ))}
              <Button onClick={() => handleAddItem(key)}>Add</Button>
            </Space>
          </Form.Item>

          {answers && (
            <Form.Item
              label={
                <span className="font-bold" style={{ fontWeight: "bold" }}>
                  Answers
                </span>
              }
              className="w-full"
            >
              <Space direction="vertical" className="flex flex-col w-full">
                {answers.map((item, index) => (
                  <div key={index} className="flex flex-row items-center w-full justify-between">
                    <span className="" style={{ marginRight: "10px", minWidth: "120px" }}>
                      {item.val}
                    </span>
                    <Input
                      value={item.text}
                      onChange={(e) => handleAnswerInputChange(key, index, e.target.value)}
                      className="mr-10"
                      style={{ marginRight: "10px" }}
                    />
                  </div>
                ))}
              </Space>
            </Form.Item>
          )}
          <hr style={{ background: "#000", height: "2px", marginBottom: "20px", marginTop: "10px" }} />
        </div>
      );
    });
  };

  return (
    <div className="px-6 py-8 mx-auto max-w-screen-2xl xl:px-12">
      <div className="w-full">
        <div className="bg-white rounded-md shadow-sm shadow-gray-200 ">
          <div className="flex items-center justify-between px-4 py-4 border-b border-gray-200 lg:px-6">
            <div className="flex items-center justify-between flex-col w-full">
              <h2 className="font-bold pb-50" style={{ marginBottom: "30px", fontWeight: "bold" }}>
                Warm up form
              </h2>
              {!loading && (
                <Form layout="vertical" className="flex-col w-full">
                  {renderInputFields()}
                  <Form.Item style={{ marginTop: "50px" }}>
                    <Button onClick={handleSubmit}>Save</Button>
                    {saved && <span className="ml-10 text-green-500">Saved!</span>}
                  </Form.Item>
                </Form>
              )}
              {loading && <Spin />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarmUpForm;
