import React, { useRef } from "react";
import axios from "axios";
import Utils, { token_cookie_name } from "../../utils";

interface Props {
  setModal: (status: boolean) => void;
  // url: any;
  filename: string;
  content: string;
}

const Preview = (props: Props) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const saveDoc = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (textAreaRef && textAreaRef.current) {
      const data = {
        filename: props.filename,
        content: textAreaRef.current.value,
      };
      const response = await axios.post(`${process.env.REACT_APP_CHAT_SERVER}/api/doc/update`, data, {
        headers: {
          Authorization: "Bearer " + Utils.getCookie(token_cookie_name),
        },
      });
      console.info("response", response.data);
      props.setModal(false);
    }
  };

  return (
    <div
      className="fixed inset-0 z-30 flex items-start justify-between p-4 pt-20 md:items-center md:pt-0"
      x-data="{ modalOpen: false }"
      x-init="setTimeout(() => modalOpen = true, 300)"
      x-show="modalOpen"
    >
      <div
        className="absolute inset-0 bg-gray-600 bg-opacity-30"
        data-transition-enter="ease-in-out duration-300"
        data-transition-enter-start="opacity-0"
        data-transition-enter-end="opacity-100"
        data-transition-leave="ease-in-out duration-300"
        data-transition-leave-start="opacity-100"
        data-transition-leave-end="opacity-0"
      ></div>
      <div
        className="relative z-50 w-full max-w-2xl pt-1 mx-auto overflow-y-auto bg-white rounded shadow-xl shadow-gray-300 max-h-[calc(100%_-_4rem)] lg:max-h-[calc(100%_-_10rem)]"
        data-show="modalOpen"
        data-transition-enter="ease-in-out duration-500"
        data-transition-enter-start="scale-0 opacity-20"
        data-transition-enter-end="scale-100 opacity-100"
        data-transition-leave="ease-in-out duration-500"
        data-transition-leave-start="scale-100 opacity-100"
        data-transition-leave-end="scale-0 opacity-0"
      >
        <div className="relative h-full px-4 py-4 border-t border-gray-200 md:py-4 md:px-6 ">
          <form action="#" className="space-y-4">
            <div className="flex">
              <div className="flex-1">
                {/* <embed
                  src={
                    "https://drive.google.com/viewerng/viewer?embedded=true&url=https://docsbucket1010.s3.us-west-2.amazonaws.com/" +
                    props.url
                  }
                  width="600"
                  height="800"
                ></embed> */}
                <textarea
                  ref={textAreaRef}
                  rows={25}
                  className="w-full px-4 py-2 text-sm border border-gray-300 rounded-md resize-none focus:ring-blue-500 focus:border-blue-500"
                  defaultValue={props.content}
                />
              </div>
            </div>

            <div className="flex justify-end pt-3 mt-6 space-x-3 border-t">
              <button
                className="flex items-center px-4 py-3 space-x-2 text-sm font-semibold leading-4 text-gray-700 transition-colors duration-300 bg-gray-100 rounded-md hover:bg-gray-300"
                onClick={saveDoc}
              >
                <span>Save</span>
              </button>
              <button
                className="flex items-center px-4 py-3 space-x-2 text-sm font-semibold leading-4 text-gray-700 transition-colors duration-300 bg-gray-100 rounded-md hover:bg-gray-300"
                onClick={() => props.setModal(false)}
              >
                <span>Cancel</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Preview;
