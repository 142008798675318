import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import PDFChatQA from "./components/chatqa";
import PdfList from "./components/documents";
import WarmUpForm from "./components/warmupForm";
import StaticResponses from "./components/static";
import { Spin } from "antd";
import Utils, { token_cookie_name } from "./utils";

function App() {
  const isProd = process.env.REACT_APP_ENV === "production";
  const [authed, setAuthed] = React.useState<boolean>(false);
  useEffect(() => {
    if (!window || !window.location) return;

    if (!process.env.REACT_APP_COGNITO_CALLBACK_URL) {
      console.error("REACT_APP_COGNITO_CALLBACK_URL not set");
      return;
    }

    const URItoken = Utils.getToken();
    if (URItoken) {
      Utils.setCookie(URItoken);
      // send user back to the home page
      window.location.href = process.env.REACT_APP_COGNITO_CALLBACK_URL;
      return;
    }

    const accessToken = Utils.getCookie(token_cookie_name);
    if (accessToken) {
      setAuthed(true);
    } else {
      Utils.authorize();
    }
  }, []);

  return (
    <Router>
      {authed && (
        <main className="flex flex-col min-h-screen bg-gray-100">
          <header className="shadow-sm shadow-gray-400">
            <div className="flex justify-end px-6 py-3 bg-gray-900 border-b border-black sm:justify-between xl:px-12">
              <div className="flex items-center flex-1 space-x-2">
                <div className="flex-1 flex justify-end">
                  <Link to="/" className="text-white mr-4">
                    Documents
                  </Link>
                  <Link to="/forms" className="text-white mr-4">
                    Forms
                  </Link>
                  <Link to="/static" className="text-white mr-4">
                    System Responses
                  </Link>
                  <Link to="/testing" className="text-white">
                    Testing
                  </Link>
                </div>
              </div>
            </div>
          </header>
          <div className="flex flex-row flex-1 w-full max-w-screen-xl px-6 py-8 mx-auto xl:px-12">
            <div className="flex justify-center w-full px-4">
              <div className="w-full">
                <div className="w-full text-left">
                  {!isProd && (
                    <h2 className="text-left font-bold" style={{ fontWeight: "bold", fontSize: "30px" }}>
                      STAGING
                    </h2>
                  )}
                  <Routes>
                    <Route path="/" element={<PdfList />} />
                    <Route path="/testing" element={<PDFChatQA />} />
                    <Route path="/forms" element={<WarmUpForm />} />
                    <Route path="/static" element={<StaticResponses />} />
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
      {!authed && (
        <div className="flex flex-col items-center justify-center h-screen">
          <br />
          <br />
          <Spin />
          <br />
          <br />
          Authenticating...
        </div>
      )}
    </Router>
  );
}

export default App;
