import React, { useEffect, useState } from "react";
import { Form, Input, Button, Space, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import "./static.css";
import axios from "axios";
import Utils, { token_cookie_name } from "../../utils";
// import { auth } from "../../cognito_utils";

interface FormData {
  [key: string]: string | string[];
}

const StaticResponses = () => {
  const [formData, setFormData] = useState<FormData>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [saved, setSaved] = useState<boolean>(false);

  useEffect(() => {
    console.info("useEffect static");
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_CHAT_SERVER}/api/settings`, {
          headers: {
            Authorization: "Bearer " + Utils.getCookie(token_cookie_name),
            "Content-Type": "application/json",
          },
        });
        setLoading(false);
        if (response?.data?.static) setFormData(response.data.static);
        else {
          alert("Could not fetch static responses");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (key: string, value: string) => {
    setFormData((prevFormData) => ({ ...prevFormData, [key]: value }));
  };

  const handleArrayInputChange = (key: string, index: number, value: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: (prevFormData[key] as Array<string>).map((item, i) => (i === index ? value : item)),
    }));
  };

  const handleAddItem = (key: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: [...((prevFormData[key] as Array<string>) || []), ""],
    }));
  };

  const handleRemoveItem = (key: string, index: number) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: (prevFormData[key] as Array<string>).filter((item, i) => i !== index),
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.info("Saving", formData);
    try {
      const response = await axios.post(`${process.env.REACT_APP_CHAT_SERVER}/api/static/save`, formData, {
        headers: {
          Authorization: "Bearer " + Utils.getCookie(token_cookie_name),
          "Content-Type": "application/json",
        },
      });
      setSaved(true);
      setTimeout(() => {
        setSaved(false);
      }, 5000);
      console.info("Save response", response);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const renderInputFields = () => {
    return Object.keys(formData).map((key) => {
      const value = formData[key] || (Array.isArray(formData[key]) ? [] : "");
      const label = key
        .replace(/_/g, " ")
        .toLowerCase()
        .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
          return match.toUpperCase();
        });

      return (
        <div key={key} className="w-full">
          <Form.Item
            label={
              <span className="font-bold" style={{ fontWeight: "bold" }}>
                {label} <i className="text-xs">({key})</i>
              </span>
            }
            className="w-full"
          >
            {Array.isArray(formData[key]) ? (
              <Space direction="vertical" className="flex flex-col w-full">
                {(value as Array<string>).map((item, index) => (
                  <div key={index} className="flex flex-row items-center w-full justify-between">
                    <Input
                      value={item}
                      onChange={(e) => handleArrayInputChange(key, index, e.target.value)}
                      className="mr-10"
                      style={{ marginRight: "10px" }}
                    />

                    {(value as Array<string>).length > 1 && (
                      <Button onClick={() => handleRemoveItem(key, index)}>Remove</Button>
                    )}
                  </div>
                ))}
                <Button onClick={() => handleAddItem(key)}>Add</Button>
              </Space>
            ) : (
              <TextArea value={value as string} onChange={(e) => handleInputChange(key, e.target.value)} />
            )}
          </Form.Item>
        </div>
      );
    });
  };

  return (
    <div className="px-6 py-8 mx-auto max-w-screen-2xl xl:px-12">
      <div className="w-full">
        <div className="bg-white rounded-md shadow-sm shadow-gray-200 ">
          <div className="flex items-center justify-between px-4 py-4 border-b border-gray-200 lg:px-6">
            <div className="flex items-center justify-between flex-col w-full">
              <h2 className="font-bold pb-50" style={{ marginBottom: "30px", fontWeight: "bold" }}>
                Static Responses
              </h2>
              {!loading && (
                <Form layout="vertical" className="flex-col w-full">
                  {renderInputFields()}
                  <Form.Item style={{ marginTop: "50px" }}>
                    <Button onClick={handleSubmit}>Save</Button>
                    {saved && <span className="ml-10 text-green-500">Saved!</span>}
                  </Form.Item>
                </Form>
              )}
              {loading && <Spin />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaticResponses;
