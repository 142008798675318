import React, { useState } from "react";
import { Input, Button, List, Avatar, message } from "antd";
import { SendOutlined } from "@ant-design/icons";
import axios from "axios";
import Utils, { token_cookie_name } from "../../utils";
// import { auth } from "../../utils";

const ChatBox = () => {
  const [history, setHistory] = useState([
    {
      type: "human",
      text: "Hello!",
    },
    {
      type: "bot",
      text: "Ask me any questions related to this PDF context.",
    },
  ]);
  const [prompt, setPrompt] = useState("");
  const [isAnswered, setIsAnswered] = useState(true);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!isAnswered) {
      message.warning("AI Bot is thinking! Please wait!");
      return;
    }
    setIsAnswered(false);
    history.push({ type: "human", text: prompt });
    setHistory([...history]);
    setPrompt("");

    let formData = new FormData();
    formData.append("prompt", prompt);

    const response = await axios.post(`${process.env.REACT_APP_CHAT_SERVER}/api/chat`, formData, {
      headers: {
        Authorization: "Bearer " + Utils.getCookie(token_cookie_name),
        "content-type": "text/json",
      },
    });
    history.push({ type: "bot", text: response.data.answer });
    setHistory([...history]);
    setIsAnswered(true);
  };

  return (
    <div style={{ flex: 1 }}>
      <List
        className="chat_history"
        itemLayout="horizontal"
        dataSource={history}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              className="chat"
              avatar={<Avatar src={item.type === "human" ? "/assets/images/user.png" : "/assets/images/bot.png"} />}
              title={item.type === "human" ? "Question" : "Answer"}
              description={item.text}
            />
          </List.Item>
        )}
      ></List>
      <form className="message_box" onSubmit={handleSubmit}>
        <Input.Group compact>
          <Input
            style={{ width: "calc(100% - 50px)", textAlign: "left" }}
            onChange={(e) => setPrompt(e.target.value)}
            size="large"
            value={prompt}
          />
          <Button htmlType="submit" type="primary" shape="circle" icon={<SendOutlined />} size="large" />
        </Input.Group>
      </form>
    </div>
  );
};

export default ChatBox;
