import { useState } from "react";
import PDFUpload from "../chatqa/pdf-upload-dom";
import axios from "axios";
import Utils, { token_cookie_name } from "../../utils";
// import { auth } from "../../utils";

interface Props {
  setModal: (status: boolean) => void;
  getFiles: () => void;
}

const Add = (props: Props) => {
  // const [desc, setDesc] = useState<string>("");
  // const [tags, setTags] = useState<string>("");
  const [mData, setMData] = useState<string>("Application");
  const [currentFile, setCurrentFile] = useState<FileList | null>(null);
  const [loading, setLoading] = useState(false);

  const uploadFile = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (loading) return;

    setLoading(true);
    e.preventDefault();
    for (let i = 0; i < currentFile!.length; i++) {
      let file = currentFile!.item(i);
      let formData = new FormData();
      formData.append("file", file!);
      formData.append("desc", ""); //desc);
      formData.append("tags", ""); //tags);
      formData.append("mdata", mData);
      formData.append("name", file!.name);
      formData.append("size", file!.size.toString());
      const response = await axios.post(`${process.env.REACT_APP_CHAT_SERVER}/api/upload`, formData, {
        headers: {
          Authorization: "Bearer " + Utils.getCookie(token_cookie_name),
          "content-type": "text/json",
        },
      });
      // if(response.state === "")
      if (response.data.state === "error") alert(response.data.message);
    }
    setLoading(false);
    await props.getFiles();
    await props.setModal(false);
  };

  return (
    <div
      className="fixed inset-0 z-30 flex items-start justify-between p-4 pt-20 md:items-center md:pt-0"
      x-data="{ modalOpen: false }"
      x-init="setTimeout(() => modalOpen = true, 300)"
      x-show="modalOpen"
    >
      <div
        className="absolute inset-0 bg-gray-600 bg-opacity-30"
        data-transition-enter="ease-in-out duration-300"
        data-transition-enter-start="opacity-0"
        data-transition-enter-end="opacity-100"
        data-transition-leave="ease-in-out duration-300"
        data-transition-leave-start="opacity-100"
        data-transition-leave-end="opacity-0"
      ></div>
      <div
        className="relative z-50 w-full max-w-2xl pt-1 mx-auto overflow-y-auto bg-white rounded shadow-xl shadow-gray-300 max-h-[calc(100%_-_4rem)] lg:max-h-[calc(100%_-_10rem)]"
        data-show="modalOpen"
        data-transition-enter="ease-in-out duration-500"
        data-transition-enter-start="scale-0 opacity-20"
        data-transition-enter-end="scale-100 opacity-100"
        data-transition-leave="ease-in-out duration-500"
        data-transition-leave-start="scale-100 opacity-100"
        data-transition-leave-end="scale-0 opacity-0"
      >
        <div className="flex items-center justify-between px-4 py-3 md:px-6">
          <div className="relative flex items-center space-x-2">
            <h2 className="text-base font-semibold text-gray-800">Add New Document</h2>
          </div>
          <button
            className="flex items-center justify-center w-8 h-8 p-1 text-gray-600 transition-colors duration-300 translate-x-3 bg-white rounded-full hover:bg-gray-100"
            onClick={() => props.setModal(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="relative h-full px-4 py-4 border-t border-gray-200 md:py-4 md:px-6 ">
          <form action="#" className="space-y-4">
            <div className="flex">
              <div className="mr-4">
                <PDFUpload currentFile={currentFile} setCurrentFile={setCurrentFile} loading={loading} />
              </div>
              <div className="flex-1">
                {/*<div className="space-y-2">
                  <div className="w-full pt-1">
                    <label className="block text-sm font-semibold text-gray-700">
                      Description
                    </label>
                  </div>
                  <div className="flex-1">
                    <textarea
                      name="description"
                      id="description"
                      value={desc}
                      onChange={(e) => setDesc(e.target.value)}
                      className="w-full text-sm border border-gray-300 rounded-md p-2"
                    ></textarea>
                  </div>
                </div> */}
                {/* <div className="space-y-2">
                  <div className="w-full pt-1">
                    <label className="block text-sm font-semibold text-gray-700">
                      Tags
                    </label>
                  </div>
                  <div className="flex-1">
                    <input
                      type="text"
                      name="tags"
                      id="tags"
                      placeholder="Tags"
                      value={tags}
                      onChange={(e) => setTags(e.target.value)}
                      className="w-full text-sm border border-gray-300 rounded-md p-2"
                    />
                  </div>
                </div> */}
                <div className="space-y-2">
                  <div className="w-full pt-1">
                    <label className="block text-sm font-semibold text-gray-700">Meta Data</label>
                  </div>
                  <div className="flex-1">
                    <select name="m_data" id="m_data" onChange={(e) => setMData(e.target.value)} defaultValue={mData}>
                      <option value="Application">Application</option>
                      <option value="Appraisal">Appraisal</option>
                      <option value="Approvals">Approvals</option>
                      <option value="Asset backed loans">Asset backed loans</option>
                      <option value="Bank Statement loans">Bank Statement loans</option>
                      <option value="Cash out">Cash out</option>
                      <option value="Closing">Closing</option>
                      <option value="Commercial">Commercial</option>
                      <option value="Condos">Condos</option>
                      <option value="Construction">Construction</option>
                      <option value="Credit">Credit</option>
                      <option value="Downpayment">Downpayment</option>
                      <option value="DSCR">DSCR</option>
                      <option value="DTI">DTI</option>
                      <option value="Extras">Extras</option>
                      <option value="Fees">Fees</option>
                      <option value="FHA">FHA</option>
                      <option value="Fix n Flip">Fix n Flip</option>
                      <option value="Gifts">Gifts</option>
                      <option value="Glossary">Glossary</option>
                      <option value="Hard money loans">Hard money loans</option>
                      <option value="HELOC">HELOC</option>
                      <option value="Income & assets">Income & assets</option>
                      <option value="Interest only">Interest only</option>
                      <option value="Investment">Investment</option>
                      <option value="Land">Land</option>
                      <option value="Link bank accounts">Link bank accounts</option>
                      <option value="LLC">LLC</option>
                      <option value="Loan amount">Loan amount</option>
                      <option value="Loan types">Loan types</option>
                      <option value="Mobile homes">Mobile homes</option>
                      <option value="Multi-family">Multi-family</option>
                      <option value="Old-Outout">Old-Outout</option>
                      <option value="Opt out">Opt out</option>
                      <option value="Points">Points</option>
                      <option value="Portfolio">Portfolio</option>
                      <option value="Prepayment penalty">Prepayment penalty</option>
                      <option value="Rate lock">Rate lock</option>
                      <option value="Rates">Rates</option>
                      <option value="Refinance">Refinance</option>
                      <option value="Renovations">Renovations</option>
                      <option value="Rental properties">Rental properties</option>
                      <option value="Repayments">Repayments</option>
                      <option value="Reserves">Reserves</option>
                      <option value="Seasoning">Seasoning</option>
                      <option value="Self employed">Self employed</option>
                      <option value="Servicing">Servicing</option>
                      <option value="Single Family">Single Family</option>
                      <option value="Speak to someone">Speak to someone</option>
                      <option value="States serviced">States serviced</option>
                      <option value="STR">STR</option>
                      <option value="TBD">TBD</option>
                      <option value="Timeline">Timeline</option>
                      <option value="Title">Title</option>
                      <option value="Upload docs">Upload docs</option>
                      <option value="VA">VA</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end pt-3 mt-6 space-x-3 border-t">
              <button
                className="flex items-center px-4 py-3 space-x-2 text-sm font-semibold leading-4 text-gray-700 transition-colors duration-300 bg-gray-100 rounded-md hover:bg-gray-300"
                onClick={() => props.setModal(false)}
              >
                <span>Cancel</span>
              </button>
              <button
                className="flex px-4 py-3 text-sm font-semibold leading-4 transition-colors duration-300 bg-blue-600 rounded-md hover:bg-blue-700 text-blue-50"
                onClick={(e) => uploadFile(e)}
              >
                <span>Add Document</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Add;
