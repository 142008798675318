export type AuthHash = {
  // access_token?: string;
  // expires_in?: string;
  // id_token?: string;
  // token_type?: string;
  [key: string]: string;
};

export const token_cookie_name = "ai_backoffice_access_token";

export default class Utils {
  // Parses the URL parameters and returns an object
  public static parseParameters(str: string): AuthHash {
    const pieces = str.split("&");
    const data: AuthHash = {};
    let i;
    let parts: string[];
    for (i = 0; i < pieces.length; i++) {
      parts = pieces[i].split("=");
      if (parts.length < 2) {
        parts.push("");
      }
      data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
    }
    return data;
  }

  public static getCookie(name: string): string | null {
    const nameLenPlus = name.length + 1;
    return (
      document.cookie
        .split(";")
        .map((c) => c.trim())
        .filter((cookie) => {
          return cookie.substring(0, nameLenPlus) === `${name}=`;
        })
        .map((cookie) => {
          return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0] || null
    );
  }

  // parse token from url
  public static getToken(): string {
    // if cookie has access_token use that.
    if (!this.getCookie(token_cookie_name)) {
      // try to parse URL query string parameter (assuming you have been redirected by the authorizer
      const hash = this.parseParameters(document.location.hash.substring(1));
      // if parsed data contains access_token, return the token as string.
      if (hash.access_token !== undefined) {
        return hash.access_token;
      }
    }
    return "";
  }

  public static setCookie(token: string): void {
    const d = new Date();
    d.setTime(d.getTime() + 60 * 59 * 1000);
    document.cookie = `${token_cookie_name}=` + token + "; expires=" + d.toUTCString() + ";path=/";
  }

  // Send the user to the authorize endpoint for login and authorization
  public static authorize(): void {
    sessionStorage.clear();
    // clear access_token from cookie;
    document.cookie = `${token_cookie_name}=; Max-Age=-99999999;`;
    // redirect user to Cognito Authorize endpoint.
    window.location.href =
      "https://" +
      process.env.REACT_APP_COGNITO_APIURL +
      "/oauth2/authorize?client_id=" +
      process.env.REACT_APP_COGNITO_CLIENTID +
      "&response_type=token&scope=openid&redirect_uri=" +
      process.env.REACT_APP_COGNITO_CALLBACK_URL;
  }
}

declare global {
  interface String {
    cleanField: () => void;
  }
}

// eslint-disable-next-line no-extend-native
String.prototype.cleanField = function () {
  // eslint-disable-next-line no-useless-escape
  return this.replace(/\-/g, " ").replace(/\_/g, " ").toLowerCase();
};
