import { useState, useEffect } from "react";
import axios from "axios";
import Preview from "./preview";
import Utils, { token_cookie_name } from "../../utils";
// import { auth } from "../../utils";

interface Props {
  doc: any;
  colors: any;
  selected: any;
  setSelected: (items: any) => void;
  setFiles: (items: any) => void;
}

const Document = (props: Props) => {
  const [status, setStatus] = useState(0);
  const [mData, setMData] = useState("");
  const [tags, setTags] = useState("");
  const [modal, setModal] = useState(false);
  const [docContent, setDocContent] = useState();
  const [docName, setDocName] = useState<string | undefined>();

  const init = async () => {
    await setMData(props.doc.m_data);
    await setTags(props.doc.tags);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);
  const setUpdate = async () => {
    let formData = new FormData();
    formData.append("file", props.doc.id);
    formData.append("mData", mData);
    formData.append("tags", tags);
    const response = await axios.post(`${process.env.REACT_APP_CHAT_SERVER}/api/update`, formData, {
      headers: {
        Authorization: "Bearer " + Utils.getCookie(token_cookie_name),
        "content-type": "text/json",
      },
    });
    props.setFiles(response.data.files);
  };

  // const changeFileName = (name: string) => {
  //   if (name.length > 13) {
  //     let newName =
  //       name.slice(0, 5) + "..." + name.slice(name.length - 7, name.length);
  //     return newName;
  //   }
  //   return name;
  // };

  const openDoc = async (docFileName: string) => {
    setDocContent(undefined);
    setDocName(undefined);
    setModal(true);
    const response = await axios.get(`${process.env.REACT_APP_CHAT_SERVER}/api/doc?doc=${docFileName}`, {
      headers: {
        Authorization: "Bearer " + Utils.getCookie(token_cookie_name),
      },
    });

    setDocName(docFileName);
    setDocContent(response.data.content);
  };

  const getOrdinalSuffix = (dayOfMonth: any) => {
    // Handle 11th, 12th, and 13th
    if (dayOfMonth >= 11 && dayOfMonth <= 13) {
      return "th";
    }
    const lastDigit = dayOfMonth % 10;
    switch (lastDigit) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const formatDate = (date_str: string) => {
    const date = new Date(date_str);
    const dayOfMonth = date.getDate();
    const year = date.getFullYear();
    const monthName = date.toLocaleString("default", { month: "short" });
    const ordinalSuffix = getOrdinalSuffix(dayOfMonth);
    return `${dayOfMonth}${ordinalSuffix} ${monthName} ${year}`;
  };

  const checked = () => {
    let tmp = props.selected;
    if (props.selected.indexOf(props.doc.id) === -1) tmp.push(props.doc.id);
    else tmp.splice(tmp.indexOf(props.doc.id), 1);
    props.setSelected(tmp);
  };

  return (
    <tr className="min-w-0 px-4 space-x-2 overflow-hidden  md:space-x-4 md:flex-nowrap hover:bg-gray-50 lg:px-6">
      <td className="	text-center py-2" x-data="{ star: false }">
        <input
          type="checkbox"
          name=""
          id=""
          className="w-4 h-4 border-gray-400 rounded cursor-pointer"
          onClick={() => {
            checked();
          }}
        />
      </td>
      <td className=" text-left py-2">
        <span className="text-sm font-semibold text-blue-600 text-left">
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              openDoc(props.doc.name);
            }}
          >
            {props.doc.name}
          </a>
        </span>
      </td>
      {/* <td className="	text-center py-2">
        <span className="text-xs font-semibold text-gray-600 md:text-sm ">
          {props.doc.desc}
        </span>
      </td> */}
      {/* <td className="	text-center py-2">
        {status !== 2 ? (
          <span
            className="text-xs font-semibold text-gray-600 md:text-sm flex justify-center"
            onClick={() => setStatus(2)}
          >
            &nbsp;
            {props.doc.tags
              .replaceAll(" ", "")
              .split(",")
              .map((tag: any, key: any) => (
                <p
                  key={key}
                  style={{
                    backgroundColor: `rgb(${
                      props.colors.filter((item: any) => item.tag === tag)[0].r
                    },${
                      props.colors.filter((item: any) => item.tag === tag)[0].g
                    },${
                      props.colors.filter((item: any) => item.tag === tag)[0].b
                    })`,
                    color: "white",
                  }}
                  className="px-2 rounded-md mr-2"
                >
                  {tag}
                </p>
              ))}
            &nbsp;
          </span>
        ) : (
          <div className="flex">
            <input
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              className="mr-2 rounded-md px-2"
            />
            <button
              onClick={() => {
                setStatus(0);
                setUpdate();
              }}
              className="cursor-pointer bg-sky-500 hover:bg-cyan-600 text-white px-2 rounded-md"
            >
              Save
            </button>
          </div>
        )}
      </td> */}
      <td className="	text-center py-2">
        {status !== 1 ? (
          <span
            className="text-xs font-semibold text-gray-600 md:text-sm w-48"
            // onClick={() => setStatus(1)}
          >
            &nbsp;{props.doc.m_data}&nbsp;
          </span>
        ) : (
          <div className="flex">
            <input value={mData} onChange={(e) => setMData(e.target.value)} className="mr-2 rounded-md px-2" />
            <button
              onClick={() => {
                setStatus(0);
                setUpdate();
              }}
              className="cursor-pointer bg-sky-500 hover:bg-cyan-600 text-white px-2 rounded-md"
            >
              Save
            </button>
          </div>
        )}
      </td>
      <td className="	text-center py-2">{formatDate(props.doc.created_at)}</td>
      {modal === true && docName ? <Preview setModal={setModal} content={docContent ?? ""} filename={docName} /> : null}
    </tr>
  );
};

export default Document;
