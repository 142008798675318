import { useState, useEffect, useRef } from "react";
import Document from "./document";
import Add from "./add";
import axios from "axios";
// import { auth } from "../../utils";
import { Spin } from "antd";
import Utils, { token_cookie_name } from "../../utils";

interface SearchFormData {
  searchInDocs: boolean;
  searchQuery: string;
}

const PdfList = () => {
  const searchFormRef = useRef<HTMLFormElement>(null);
  const [files, setFiles] = useState([]);
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);
  const [colors, setColors] = useState([{}]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);

  const updateFiles = (u_files: any) => {
    setFiles(u_files);
    setData(u_files);
    let currentColors = colors;
    for (let file of u_files) {
      let tags = file.tags?.replaceAll(" ", "").split(",");
      for (let tag of tags) {
        let isContain: Number = currentColors.filter((item: any) => item.tag === tag).length;
        if (!isContain)
          currentColors.push({
            tag: tag,
            r: Math.round(Math.random() * 255),
            g: Math.round(Math.random() * 255),
            b: Math.round(Math.random() * 255),
          });
      }
    }
    setColors(currentColors);
  };

  const getFiles = async () => {
    const response = await axios.post(`${process.env.REACT_APP_CHAT_SERVER}/api/history`, null, {
      headers: {
        Authorization: "Bearer " + Utils.getCookie(token_cookie_name),
        "content-type": "text/json",
      },
    });
    setLoading(false);
    await setFiles(response.data.files);
    await setData(response.data.files);
    let currentColors = colors;
    for (let file of response.data.files) {
      let tags = file.tags.replaceAll(" ", "").split(",");
      for (let tag of tags) {
        let isContain: Number = currentColors.filter((item: any) => item.tag === tag).length;
        if (!isContain)
          currentColors.push({
            tag: tag,
            r: Math.round(Math.random() * 255),
            g: Math.round(Math.random() * 255),
            b: Math.round(Math.random() * 255),
          });
      }
    }
    setColors(currentColors);
  };
  useEffect(() => {
    getFiles();
    // eslint-disable-next-line
  }, []);

  const deleteDocs = async () => {
    if (window.confirm("Are you really want to remove this file?")) {
      let formData = new FormData();
      formData.append("file", selected.join(","));
      const response = await axios.post(`${process.env.REACT_APP_CHAT_SERVER}/api/removeFile`, formData, {
        headers: {
          Authorization: "Bearer " + Utils.getCookie(token_cookie_name),
          "content-type": "text/json",
        },
      });
      setFiles(response.data.files);
      setData(response.data.files);
    }
  };

  const getSearch = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const formData: SearchFormData = {
      searchInDocs: searchFormRef.current?.searchInDocs.checked,
      searchQuery: searchFormRef.current?.searchQuery.value,
    };

    if (formData.searchInDocs) {
      const response = await axios.get(`${process.env.REACT_APP_CHAT_SERVER}/api/search?q=${formData.searchQuery}`, {
        headers: {
          Authorization: "Bearer " + Utils.getCookie(token_cookie_name),
        },
      });

      console.info("response.data.docs", response.data.docs);
      const matchingDocs = files.filter((file: any) =>
        response.data.docs.some((doc: any) => doc.cleanField() === file.name.cleanField())
      );
      console.info("matchingDocs", matchingDocs);

      setData(matchingDocs);
      setLoading(false);
    } else {
      setData(
        files.filter(
          (file: any, key: any) =>
            file.name.cleanField().search(formData.searchQuery.cleanField()) >= 0 ||
            file.desc?.cleanField().search(formData.searchQuery.cleanField()) >= 0 ||
            file.tags?.cleanField().search(formData.searchQuery.cleanField()) >= 0 ||
            file.m_data?.cleanField().search(formData.searchQuery.cleanField()) >= 0
        )
      );
      setLoading(false);
    }
  };

  const selectAll = (e: any) => {
    console.info(e.target.checked);
  };

  return (
    <div className="px-6 py-8 mx-auto max-w-screen-2xl xl:px-12">
      <div className="w-full">
        <div className="bg-white rounded-md shadow-sm shadow-gray-200 ">
          <div className="flex items-center justify-between px-4 py-4 border-b border-gray-200 lg:px-6">
            <div className="flex items-center ">
              <input
                type="checkbox"
                name=""
                id=""
                className="inline-block w-4 h-4 mr-2 border-gray-400 rounded cursor-pointer"
                onClick={(e) => selectAll(e)}
              />

              <button
                className="flex items-center justify-center w-8 h-8 p-1 text-gray-700 transition-colors duration-300 bg-white rounded-full hover:bg-gray-100"
                onClick={() => deleteDocs()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </button>
            </div>
            <button
              className="flex items-center px-4 py-3 space-x-1 text-sm font-semibold leading-4 transition-colors duration-300 bg-blue-600 rounded-md hover:bg-blue-700 text-blue-50"
              onClick={() => setModal(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                ></path>
              </svg>
              <span>New Document</span>
            </button>
          </div>
          <div className="flex items-center justify-between space-x-4">
            <form action="" className="relative flex-1" ref={searchFormRef}>
              <input type="checkbox" name="searchInDocs" id="searchInDocs" className="peer" />
              <div className="relative flex-1">
                <input
                  id="searchQuery"
                  type="text"
                  placeholder="Search term..."
                  className="block w-full py-3 pl-12 placeholder-gray-400 transition-colors duration-300 border-0 border-b border-gray-200 border-transparen peer focus:border-b-blue-600 focus:border-transparent ring-transparent focus:ring-transparent focus:ring-opacity-80 focus:bg-gray-50 "
                />
                <button
                  className="absolute flex items-center justify-center w-8 h-8 p-1 text-gray-600 transition-all duration-300 bg-transparent rounded-full left-3 top-2 hover:bg-gray-100 peer-focus:text-blue-600"
                  onClick={getSearch}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>
          </div>
          <table className="border-collapse border border-slate-400 min-w-0 px-4 space-x-2 overflow-hidden md:space-x-4 md:flex-nowrap lg:px-6 w-full rounded-md">
            <thead>
              <tr className="">
                <th className="border border-slate-300 text-center py-2">Select</th>
                <th className="border border-slate-300 text-center py-2">Name</th>
                {/* <th className="border border-slate-300 text-center py-2">
                  Description
                </th> */}
                {/* <th className="border border-slate-300 text-center py-2">
                  Tags
                </th> */}
                <th className="border border-slate-300 text-center py-2">Meta Data</th>
                <th className="border border-slate-300 text-center py-2">Date</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 && !loading ? (
                data.map(
                  (file: any, key) =>
                    file.status === "0" && (
                      <Document
                        key={key}
                        doc={file}
                        colors={colors}
                        selected={selected}
                        setSelected={setSelected}
                        setFiles={updateFiles}
                      />
                    )
                )
              ) : (
                <tr>
                  <td colSpan={4} className="w-full text-center p-20" style={{ padding: "20px" }}>
                    {loading && <Spin />}
                    {!loading && <>There are no uploaded files</>}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {modal === true ? <Add setModal={setModal} getFiles={getFiles} /> : null}
    </div>
  );
};

export default PdfList;
